import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_CATEGORY = gql`
    query GetCategory{
        category {
            id
            title
        }
    }
`

export const useCategory = () => {
    const language = useReactiveVar(appLanguage)
    const { data, loading,  } = useQuery(GET_CATEGORY, {
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { list: data?.category, loading }
};
