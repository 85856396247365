import './workoutCard.scss'
import {Link} from "react-router-dom";
import {GetUrlWithLocal} from "../../../../utils/getUrlWithLocal";

function WorkoutCard(props){
    const {item,translations} = props
    return <Link to={GetUrlWithLocal(`/fitness/${item?.id}`)} className={'workout-card-wrapper'}>
        <div className={'workout-card-img'}>
            <img src={item?.image_url} alt=""/>
        </div>
        <div className={'workout-card-text'}>
            <div className={'workout-card-title'}>
                {item?.title}
            </div>
            <div className={'workout-card-description'}>
                {item?.description}
            </div>
            <button className={'workout-card-btn'}>
                {translations?.about_page_explore_btn_title}
            </button>
        </div>
    </Link>
}export default WorkoutCard