import moment from "moment";
import {EventCheckboxIcon, ViewIcon} from "../../../../assets/images";
import './artEventCard.scss'
import {Link} from "react-router-dom";
import {useTranslations} from "../../../../graphql/query.hooks/translation.hook";
import {GetUrlWithLocal} from "../../../../utils/getUrlWithLocal";


function ArtEventCard ({ data,slug }) {
    const translations = useTranslations()

    return <Link to={GetUrlWithLocal(`/${slug}/detail/${data?.id}`)} className={'events-blocks-wrapper'}>
        <div className={'events-img-block'}>
            <div className={'img-wrapper'}>
                <img src={data?.image_url} alt=""/>
            </div>
        </div>
        <div className={'events-info-block'}>
            <div className={'events-text-block'}>
                <div className={'text-block-title'}>
                    {data?.title}
                </div>
                <div className={'text-block-description'} dangerouslySetInnerHTML={{ __html: data?.description }}/>
                {
                    data?.is_for_club_members ? <div className={'events-card-members'}>
                        <EventCheckboxIcon/> {translations?.event_card_members}
                    </div> : ''
                }
            </div>
            <div className={'events-post-block'}>
                <div className={'post-date-block'}>
                    {translations?.event_posted_text} <span>{moment(data?.created_at).format('ll')}</span>
                </div>
                {
                    data?.public_metric?.read_count && <div className={'post-view-block'}>
                        <ViewIcon/> {data?.public_metric?.read_count}
                    </div>
                }
            </div>
        </div>
    </Link>
}
export default ArtEventCard