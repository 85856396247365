import './imagesSlider.scss'
import {useRef,useState,useEffect} from "react";
import {ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon, ModalCloseIcon} from "../../../../assets/images";
import {Modal} from "antd";
import { Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";

function ImagesSlider(props){
    const {closeModal, visible, modalImagePathList, modalCurrentImageIndex} = props;
    const [slideIndex, setSlideIndex] = useState(0);
    let sliderRef = useRef(null);


    useEffect(() => {
        if(!!visible){
            setSlideIndex(0)
        }
    }, [visible])

    useEffect(() => {
        sliderRef.slickGoTo?.(modalCurrentImageIndex)
        setSlideIndex(modalCurrentImageIndex)
    }, [modalCurrentImageIndex])

    const nextSlide = (index) => {
        setSlideIndex(index + 1 === modalImagePathList?.length ? 0 : index + 1)
    };

    const prevSlide = (index) => {
        setSlideIndex(index === 0 ? modalImagePathList?.length : index - 1)
    };


    return <Modal
        visible={visible}
        wrapClassName={'custom-modal'}
        transitionName={""}
        closeIcon={<ModalCloseIcon/>}
        centered={true}
        maskClosable={true}
        onCancel={closeModal}>
        <div className={'slider-modal-wrapper'}>
            {!!modalImagePathList?.length && <Swiper
                spaceBetween={'25'}
                slidesPerView={1}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                freeMode={true}
                modules={[Pagination, Navigation]}
            >
                {modalImagePathList?.map((item, index) => {
                    if(index === slideIndex){
                        return <SwiperSlide key={item.id}>
                            <div className={`modal-slider-wrapper`}>
                                <img src={item?.image_url} className={'about-img'}
                                     onClick={() => setSlideIndex(index)} alt={"about"}/>
                            </div>
                        </SwiperSlide>
                    }
                })}
            </Swiper>}
                <>
                    <button className={`slider-btn  prev-btn`}
                            onClick={() => prevSlide(slideIndex)}
                    >
                        <ArrowLeftIcon/>
                    </button>
                    <button
                        className={`slider-btn next-btn`}
                        onClick={() => nextSlide(slideIndex)}>
                        <ArrowRightIcon/>
                    </button>
                </>
        </div>
    </Modal>
}export default ImagesSlider