import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_WORKOUT = gql`
    query GetWorkout($section_id_in:[String!]){
        workout(where:{section_id_in:$section_id_in}) {
            id
            title
            image_url
        }
    }
`

export const useWorkout = (workoutSectionId) => {
    const language = useReactiveVar(appLanguage)
    const { data, loading, fetchMore } = useQuery(GET_WORKOUT, {
        variables: { section_id_in: workoutSectionId },
        skip: !workoutSectionId,
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { list: data?.workout, loading, fetchMore }
};
