import './footer.scss'
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import {
    FacebookIcon,
    FooterLogo,
    HeaderArrIcon,
    InstagramIcon,
    LinkedinIcon, TelegramIcon,
    TicTokIcon,
    TwitterIcon
} from "../../assets/images";
import {Dropdown, Menu, Space} from "antd";
import {useReactiveVar} from "@apollo/client";
import {appLanguage} from "../../graphql/reactive-variables";
import {apolloClient} from "../../graphql/client";
import {Languages} from "../../utils/constanats";
import {useContact} from "../../graphql/query.hooks/contact.hook";
import {GetUrlWithLocal} from "../../utils/getUrlWithLocal";

function Footer(props) {
    const {translations} = props
    const language = useReactiveVar(appLanguage)
    const contact = useContact()
    const location = useLocation()
    const navigate = useNavigate();

    function changeLanguage(lang) {
        if (language !== lang) {
            appLanguage(lang);
            localStorage.setItem('language', lang);
            apolloClient.resetStore();
            const pathname=location.pathname;
            const prevlang = pathname?.split('/').at(-1);
            if(prevlang === Languages.en || prevlang === Languages.hy){
                const pathnameWithoutLang= pathname?.slice(0, -prevlang.length)
                navigate(pathnameWithoutLang+lang, {replace: true})
            }
        }
    }


    const menuLanguage = (
        <Menu className={`languages-dropdown`}>
            {
                Object.values(Languages)?.map((code) => {
                    return language !== code &&
                        <Menu.Item key={code}
                                   onClick={() => changeLanguage(code)}
                                   className={'language-dropdown-item'}>
                            <div className="selected-language">
                                <span>{translations && translations[`header_languages_${code}`]}</span>
                            </div>
                        </Menu.Item>
                })
            }
        </Menu>
    );
    return <div className={`footer-wrapper ${location.pathname.includes('/event/detail') || location.pathname.includes('/article/detail') ? 'margin-wrapper' : ''}`}>
        <div className={'footer-top-block'}>
            <Link to={GetUrlWithLocal('/home')}>
                <div className={'orange-logo'}>
                    <FooterLogo title={''}/>
                </div>
            </Link>
            <ul className="nav-menu">
                <NavLink to={GetUrlWithLocal('/fitness')} className={({isActive}) => (isActive ? "active" : 'none')}>
                    <li className="nav-item">{translations?.footer_section_fitness}</li>
                </NavLink>
                <NavLink to={GetUrlWithLocal('/spa')} className={({isActive}) => (isActive ? "active" : 'none')}>
                    <li className="nav-item">{translations?.footer_section_spa}</li>
                </NavLink>
                <NavLink to={GetUrlWithLocal('/cafe')} className={({isActive}) => (isActive ? "active" : 'none')}>
                    <li className="nav-item">{translations?.footer_section_cafe}</li>
                </NavLink>
                <NavLink to={GetUrlWithLocal('/about')} className={({isActive}) => (isActive ? "active" : 'none')}>
                    <li className="nav-item">{translations?.footer_section_about}</li>
                </NavLink>
                <NavLink to={GetUrlWithLocal('/news')} className={({isActive}) => (isActive ? "active" : 'none')}>
                    <li className="nav-item">{translations?.footer_section_events}</li>
                </NavLink>
                <NavLink to={GetUrlWithLocal('/contact')} className={({isActive}) => (isActive ? "active" : 'none')}>
                    <li className="nav-item">{translations?.footer_section_contact}</li>
                </NavLink>
                <NavLink to={GetUrlWithLocal('/regulation')} className={({isActive}) => (isActive ? "active" : 'none')}>
                    <li className="nav-item">{translations?.footer_regulation}</li>
                </NavLink>
                <NavLink to={GetUrlWithLocal('/privacy')} className={({isActive}) => (isActive ? "active" : 'none')}>
                    <li className="nav-item">{translations?.footer_privacy_police}</li>
                </NavLink>
                <div>
                    <Dropdown
                        trigger={['click']}
                        overlay={menuLanguage}
                        destroyPopupOnHide={false}
                        autoFocus={false}
                        autoAdjustOverflow={false}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <div className="nav-item">
                                    {Object.values(Languages).map(code => {
                                        if (code === language) {
                                            return translations && translations[`header_languages_${code}`]
                                        }
                                    })} <HeaderArrIcon/>
                                </div>
                            </Space>
                        </a>
                    </Dropdown>
                </div>
            </ul>
        </div>
        <div className={'footer-responsive-block'}>
            <div className={'logo-block'}>
                <Link to={GetUrlWithLocal('/home')}>
                    <div className={'orange-logo'}>
                        <FooterLogo title={''}/>
                    </div>
                </Link>
                <div>
                    <Dropdown
                        trigger={['click']}
                        overlay={menuLanguage}
                        destroyPopupOnHide={false}
                        autoFocus={false}
                        autoAdjustOverflow={false}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <div className="nav-item">
                                    {Object.values(Languages).map(code => {
                                        if (code === language) {
                                            return translations && translations[`header_languages_${code}`]
                                        }
                                    })} <HeaderArrIcon/>
                                </div>
                            </Space>
                        </a>
                    </Dropdown>
                </div>
            </div>
            <div className={'page-wrapper'}>
                <div className={'page-block'}>
                    <NavLink to={GetUrlWithLocal('/fitness')} className={({isActive}) => (isActive ? "active" : 'none')}>
                        <li className="nav-item">{translations?.footer_section_fitness}</li>
                    </NavLink>
                    <NavLink to={GetUrlWithLocal('/spa')} className={({isActive}) => (isActive ? "active" : 'none')}>
                        <li className="nav-item">{translations?.footer_section_spa}</li>
                    </NavLink>
                    <NavLink to={GetUrlWithLocal('/regulation')} className={({isActive}) => (isActive ? "active" : 'none')}>
                        <li className="nav-item">{translations?.footer_regulation}</li>
                    </NavLink>
                </div>
                <div className={'page-block'}>
                    <NavLink to={GetUrlWithLocal('/cafe')} className={({isActive}) => (isActive ? "active" : 'none')}>
                        <li className="nav-item">{translations?.footer_section_cafe}</li>
                    </NavLink>
                    <NavLink to={GetUrlWithLocal('/about')} className={({isActive}) => (isActive ? "active" : 'none')}>
                        <li className="nav-item">{translations?.footer_section_about}</li>
                    </NavLink>
                    <NavLink to={GetUrlWithLocal('/privacy')} className={({isActive}) => (isActive ? "active" : 'none')}>
                        <li className="nav-item">{translations?.footer_privacy_police}</li>
                    </NavLink>
                </div>
                <div className={'page-block'}>
                    <NavLink to={GetUrlWithLocal('/news')} className={({isActive}) => (isActive ? "active" : 'none')}>
                        <li className="nav-item">{translations?.footer_section_events}</li>
                    </NavLink>
                    <NavLink to={GetUrlWithLocal('/contact')} className={({isActive}) => (isActive ? "active" : 'none')}>
                        <li className="nav-item">{translations?.footer_section_contact}</li>
                    </NavLink>
                </div>
            </div>
            <div className={'footer-responsive-social-medias'}>
                <a href={contact?.list?.fb_link} target={'_blank'}>
                    <FacebookIcon/>
                </a>
                <a href={contact?.list?.in_link} target={'_blank'}>
                    <LinkedinIcon/>
                </a>
                <a href={contact?.list?.tk_link} target={'_blank'}>
                    <TicTokIcon/>
                </a>
                <a href={contact?.list?.in_link} target={'_blank'}>
                <InstagramIcon/>
            </a>
            </div>
        </div>
        <div className={'footer-bottom-block'}>
            <div className={'footer-copyright-block'}>
                <span>{translations?.footer_copyright}</span>
                <span>{translations?.footer_developed_text}
                    <a href={'https://solidy.am/'} target={'_blank'} className={'footer-company-link'}>
                    Solidy.am</a>
                </span>
            </div>
            <div className={'footer-social-media-block'}>
                <a href={contact?.list?.fb_link} target={'_blank'}>
                    <FacebookIcon/>
                </a>
                <a href={contact?.list?.ln_link} target={'_blank'}>
                    <LinkedinIcon/>
                </a>
                <a href={contact?.list?.tk_link} target={'_blank'}>
                    <TicTokIcon/>
                </a>
                <a href={contact?.list?.tg_link} target={'_blank'}>
                    <TelegramIcon/>
                </a>
                <a href={contact?.list?.in_link} target={'_blank'}>
                <InstagramIcon/>
            </a>
            </div>
        </div>
    </div>
}

export default Footer
