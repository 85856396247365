import './videoBlock.scss'
import {PlayBtnIcon} from "../../../assets/images";
import {useEffect, useRef, useState} from "react";

function VideoBlock(props){
    const {translations, resource} = props
    const [videoPlay,setVideoPlay] = useState(false)
    const ref = useRef(null)

    function videoState(){
        setVideoPlay(false)
        let video = document.querySelector('video')
        video.load();
    }
    function videoPause() {
        const scrollY = window.scrollY || window.pageYOffset;
        const videoBottom = ref.current.getBoundingClientRect().bottom + scrollY;
        const videoTop = ref.current.getBoundingClientRect().top + scrollY;
        // console.log(videoBottom,videoTop,'window.scrollY')
        if(scrollY + (videoBottom - videoTop) < videoTop || window.scrollY > videoBottom){
            let video = document.querySelector('video')
            setVideoPlay(false)
            video.pause()
        }
    }

    useEffect(() => {
        window.addEventListener('scroll',videoPause)
        return () => window.removeEventListener('scroll',videoPause)
    },[])

    function videoStart() {
        let video = document.querySelector('video')
        setVideoPlay(true)
        video.play()
    }
    return <div className={'video-wrapper'} >
       <div className={'video-block-header'}>
           <div className={'video-block-title'}>
               {translations?.homepage_video_title}
           </div>
           <div className={'video-block-description'}>
               {translations?.homepage_video_description}
           </div>
       </div>
        <div className={'video-block'}>
            {videoPlay ? '' :  <div onClick={videoStart}>
                <PlayBtnIcon/>
            </div>}
            <video playsInline
                   muted
                   ref={ref}
                   id={'scrollArea'}
                   controls={videoPlay}
                   onEnded={videoState}
                   src={resource?.home_video_url}
                   poster={resource?.thumbnail_url}
                   className={'video'}
            >
            </video>
        </div>
    </div>

}export default VideoBlock
