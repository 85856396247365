import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_EVENTS = gql`
    query GetAEvents($offset: Int!, $limit: Int!){
        event(offset: $offset, limit: $limit) {
            current_offset
            total_count
            records {
                id
                title
                slug
                description
                is_for_club_members
                image_url
                event_day_dates
                created_at
                updated_at
            }
        }
    }
`

export const useEvents = ({ offset = 0, limit = 5 } = {}) => {
    const language = useReactiveVar(appLanguage)
    const { data, loading, fetchMore } = useQuery(GET_EVENTS, {
        variables: { offset, limit },
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { data: data?.event, loading, fetchMore }
};
