import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_TRANSLATIONS = gql`
    query GetATranslations{
        staticTexts{
            hy
            en
        }
    }
`

export const useTranslations = () => {
    const { data} = useQuery(GET_TRANSLATIONS);
    const language = useReactiveVar(appLanguage)
    return data?.staticTexts?.[language]
};
