import MainBlock from "../../components/mainBlock/MainBlock";
import TickerBlock from "../../components/homepage/tickerBlock/TickerBlock";
import AppBlock from "../../components/homepage/appBlock/AppBlock";
import {useState,useEffect} from "react";
import './pool.scss'
import ImagesSlider from "../../components/uiElements/modals/imagesSlider/ImagesSlider";
import {useTranslations} from "../../graphql/query.hooks/translation.hook";
import {usePool} from "../../graphql/query.hooks/pool.hook";


function Pool(){
    const [sliderOpen,setSliderOpen] = useState(false)
    const [imagePathList,setImagePathList] = useState([])
    const [currentImageIndex,setCurrentImageIndex] = useState(0)
    const translations = useTranslations()
    const pool = usePool()
    const showCount = window.innerWidth < 1000 ? 4 : 5

    function openImagesSlider(modalImagePathList, imageIndex) {
        setSliderOpen(true)
        setCurrentImageIndex(imageIndex)
        setImagePathList(modalImagePathList)
    }

    function closeImagesSlider() {
        setSliderOpen(false)
        setCurrentImageIndex(0)
        setImagePathList([])
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return <div className={'pool-wrapper'}>
        <MainBlock title={`${translations?.pool_page_title}`}/>
        <TickerBlock/>
        <div className={'pool-manu-block'}>
            <div className={'menu-header'}>
                <div className={'menu-title'}>
                    {pool?.data?.title}
                </div>
            </div>
            <div className={'pool-menu-images'}>
                {
                    !!pool?.data?.images?.length && pool?.data?.images.slice(0, showCount).map((img, index) => {
                        return <div className="menu-images-item"
                                    key={index}
                                    onClick={() => pool?.data?.images?.[0] && openImagesSlider(pool, index)}>
                            <div className="menu-images-content">
                                <img src={img?.image_url} alt=""/>
                                {index === showCount - 1 && (pool?.data?.images?.length > showCount) &&
                                    <div className={'more-content'}>
                                        <div className={"text-count"}>+{pool?.data?.images?.length + 1 - 5} {translations?.pool_page_more}</div>
                                    </div>}
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
        <AppBlock/>
        <ImagesSlider visible={sliderOpen}
                      modalImagePathList={imagePathList?.data?.images}
                      modalCurrentImageIndex={currentImageIndex}
                      closeModal={closeImagesSlider}/>
    </div>

}export default Pool
