import './classesSlider.scss'
import {ArrowUpIcon} from "../../assets/images";
import {useState} from "react";
import ClassesModal from "../uiElements/modals/classesModal/ClassesModal";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper/modules";


function ClassesSlider(props) {
    const {classes} = props
    const [classesModalOpen, setClassesModalOpen] = useState(false)
    const [classesModalData, setClassesModalData] = useState({})

    function openClassesModal(item) {
        setClassesModalOpen(true)
        setClassesModalData(item)
    }

    function closeClassesModal() {
        setClassesModalOpen(false)
    }


    return <div className={'classes-slider-wrapper'}>
        <Swiper
            spaceBetween={'25'}
            slidesPerView={1.2}
            loop={true}
            autoplay={{
                delay: 1000,
                disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
                600: {
                    slidesPerView: 4,
                },
            }}
        >
            {classes?.length && classes?.map(item => {
                return <SwiperSlide key={item.id}>
                    <div className="classes-item" key={item.id} onClick={() => openClassesModal(item)}>
                        <div className={'classes-img'}>
                            <img src={item?.image_url} alt=""/>
                        </div>
                        <div className={'classes-info'}>
                            <div className={'classes-title'}>
                                {item?.title}
                            </div>
                            <div className={'classes-icon'}>
                                <ArrowUpIcon/>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            })}
        </Swiper>
        <ClassesModal visible={classesModalOpen}
                      classesModalData={classesModalData}
                      closeModal={closeClassesModal}/>
    </div>
}

export default ClassesSlider
