import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_RULE = gql`
    query GetRule{
        rule{   
          id
          text
          updated_at
        }
    }
`

export const useRule = () => {
    const language = useReactiveVar(appLanguage)
    const { data, loading, fetchMore } = useQuery(GET_RULE, {
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { list: data?.rule, loading, fetchMore}
};
