import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_FITNESS_SERVICE_ONE = gql`
    query GetFitnessServiceOne($fitness_service_id_eq:String!){
        fitness_service_one(where:{fitness_service_id_eq:$fitness_service_id_eq}) {
            id
            title
            description
            image_url
            updated_at
            trainers{
                id
                name
                profession
                bio
                image_url
                updated_at
            }
            images{
                id
                image_url
                updated_at
            }
        }
    }
`

export const useFitnessServiceOne = (fitnessServiceId) => {
    const language = useReactiveVar(appLanguage)
    const { data, loading, fetchMore } = useQuery(GET_FITNESS_SERVICE_ONE, {
        variables: { fitness_service_id_eq: fitnessServiceId },
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { data: data?.fitness_service_one, loading, fetchMore }
};
