import './eventsBlock.scss'
import { Tabs } from "antd";
import {EventCheckboxIcon, SeeMoreArrIcon, ViewIcon} from "../../../assets/images";
import moment from "moment";
import { useArticles } from "../../../graphql/query.hooks/article.hook";
import { useState } from "react";
import { useEvents } from "../../../graphql/query.hooks/event.hook";
import ArtEventCard from "../../uiElements/cards/artEventCard/ArtEventCard";
import {Link} from "react-router-dom";
import {NEWS_TYPES} from "../../../constants/constType";
import {GetUrlWithLocal} from "../../../utils/getUrlWithLocal";
import {PackageTypes} from "../../../utils/constanats";

const SectionTabs = {
    articles: 'articles',
    events: 'events',
}

function EventsBlock (props) {
    const { translations } = props
    const [activeTab, setActiveTab] = useState(SectionTabs.articles)
    const articles = useArticles();
    const events = useEvents();
    const itemsList = activeTab === SectionTabs.articles ? articles?.data?.records : events?.data?.records;

    const onChange = (key) => {
        setActiveTab(key)
    };

    useEvents(() => {
        setActiveTab(SectionTabs.articles)
    },[SectionTabs.articles])


    const mainItem = itemsList?.[0]
    // const item = [];
    // if(articles?.data?.total_count){
    //     item.push(
    //         {
    //             key: SectionTabs.articles,
    //             label: `${translations?.homepage_article_title}`,
    //         },
    //     )
    // }
    // if(events?.data?.total_count){
    //     item.push(
    //         {
    //             key: SectionTabs.events,
    //             label: `${translations?.homepage_events_title}`,
    //         },
    //     )
    // }

    const items = [
        {
            key: SectionTabs.articles,
            label: `${translations?.homepage_article_title}`,
        },
        {
            key: SectionTabs.events,
            label: `${translations?.homepage_events_title}`,
        },
    ];

    return <div className={'events-wrapper'}>
        <div className={'events-block-header'}>
            {translations?.homepage_artEvents_title}
        </div>
        <Tabs value={activeTab} items={items} onChange={onChange} defaultActiveKey={SectionTabs.articles}/>
        <div className={'events-block'}>
            <Link to={GetUrlWithLocal(`/${activeTab === SectionTabs.articles ? NEWS_TYPES?.ARTICLE : NEWS_TYPES?.EVENTS}/detail/${mainItem?.id}`)} className={'events-left-block'}>
                <div className={'events-left-block-wrapper'}>
                    <div className={'events-img-left-wrapper'}>
                        <div className={'events-img-left-block'}>
                            <img src={mainItem?.image_url} alt=""/>
                        </div>
                    </div>
                    <div className={'events-info-left-block'}>
                        <div className={'text-left-block-title'}>
                            {mainItem?.title}
                        </div>
                        <div className={'text-left-block-description'}
                             dangerouslySetInnerHTML={{ __html: mainItem?.description }}/>
                        {
                            mainItem?.is_for_club_members ? <div className={'item-members'}>
                                <EventCheckboxIcon/> {translations?.event_card_members}
                            </div> : ''
                        }
                        <div className={'events-post-left-block'}>
                            <div className={'post-date-left-block'}>
                                {translations?.event_posted_text} <span>{moment(mainItem?.created_at).format('ll')}</span>
                            </div>
                            {
                                mainItem?.public_metric?.read_count && <div className={'post-view-left-block'}>
                                    <ViewIcon/> {mainItem?.public_metric?.read_count}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Link>
            {
                window.innerWidth < 1050 && <ArtEventCard key={mainItem?.id} data={mainItem} slug={activeTab === SectionTabs.articles ? NEWS_TYPES?.ARTICLE : NEWS_TYPES?.EVENTS}/>
            }
            <div className={'events-right-block'}>
                {
                    activeTab === SectionTabs.articles ?  itemsList?.slice(1, 4)?.map(item => {
                        return <ArtEventCard key={item.id} data={item} slug={activeTab === SectionTabs.articles && NEWS_TYPES.ARTICLE}/>
                    }) :  itemsList?.slice(1, 4)?.map(item => {
                        return <ArtEventCard key={item.id} data={item} slug={activeTab === SectionTabs.events && NEWS_TYPES.EVENTS}/>
                    })
                }
                <Link to={GetUrlWithLocal('/news')} className={'event-see-more'}>
                    {translations?.event_seeMore_btn} <SeeMoreArrIcon/>
                </Link>
            </div>
        </div>
    </div>

}

export default EventsBlock


