import MainBlock from "../../components/mainBlock/MainBlock";
import {PagLeftIcon, PagRightIcon } from "../../assets/images";
import TickerBlock from "../../components/homepage/tickerBlock/TickerBlock";
import NewsCard from "../../components/uiElements/cards/newsCard/NewsCard";
import './events.scss'
import { useState, useEffect } from "react";
import { useArticles } from "../../graphql/query.hooks/article.hook";
import { useEvents } from "../../graphql/query.hooks/event.hook";
import { Pagination, Tabs } from "antd";
import { useTranslations } from "../../graphql/query.hooks/translation.hook";
import { useCategory } from "../../graphql/query.hooks/category.hook";
import {NEWS_TYPES} from "../../constants/constType";


function Events () {
    const translations = useTranslations()
    const [activeActiveTab, setActiveActiveTab] = useState(null)
    const [eventPage, setEventPage] = useState(1);
    const [articlePage, setArticlePage] = useState(1);

    const category = useCategory()
    const { data: eventsData, loading: loadingEvents } =
        useEvents({ offset: (eventPage - 1) * 3, limit: 3 });
    const { data: articlesData, loading: loadingArticles } =
        useArticles({
            offset: (articlePage - 1) * 6,
            limit: 6,
            categoryId: activeActiveTab
        });

    const onChangeEventPaging = (page) => {
        setEventPage(page);
    };
    const onChangeArticlePaging = (page) => {
        setArticlePage(page);
    };

    const articleCategories = [
        {
            key: null,
            label:`${translations?.event_article_label_text}`, //todo translations
        },
    ];
    category?.list?.forEach(c => {
        articleCategories?.push({
            key: c.id,
            label: c.title,
        })
    })

    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, [])

    function onChangeArticleActiveTab (activeTab) {
        setActiveActiveTab(activeTab)
        setArticlePage(1)
    }

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a className={'prev_btn'}>
                <PagLeftIcon className={'arrow_icon'}/>
            </a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>
                <PagRightIcon className={'arrow_icon'}/>
            </a>;
        }
        return originalElement;
    };

    return <div className={'events-page-wrapper'}>
        <MainBlock title={`${translations?.event_title}`}/>
        <TickerBlock/>
        {!!eventsData?.total_count && <div className={'members-events-wrapper'}>
            <div className={'members-events-header'}>
                <div className={'members-events-title'}>
                    {translations?.event_page_title}
                </div>
                <div className={'members-events-description'}>
                    {translations?.event_page_description}
                </div>
            </div>
            <div className={`members-events-blocks ${loadingEvents ? 'loading' : ''}`}>
                {
                    !!eventsData?.records?.length && eventsData?.records?.map((event, index) => {
                        return <div className={'members-events-card'} key={index}>
                            <NewsCard data={event} translations={translations} slug={NEWS_TYPES?.EVENTS}/>
                        </div>
                    })
                }
            </div>
            <Pagination current={eventPage}
                        defaultPageSize={3}
                        onChange={onChangeEventPaging}
                        itemRender={itemRender}
                        hideOnSinglePage={true}
                        className={`pagination-event`}
                        total={eventsData?.total_count || 0}/>
        </div>}

        {!!articlesData?.total_count && <div className={'blog-events-wrapper'}>
            <div className={'blog-events-header'}>
                <div className={'blog-events-title'}>
                    {translations?.event_page_blog_title}
                </div>
                <div className={'blog-events-description'}>
                    {translations?.event_page_blog_description}
                </div>
            </div>
            <Tabs value={activeActiveTab} items={articleCategories} onChange={onChangeArticleActiveTab}/>
            <div className={`blog-events-block ${loadingArticles ? 'loading' : ''}`}>
                {
                    articlesData?.records?.length && articlesData?.records?.map((article, index) => {
                        return <div className={'blogs-events-card'} key={index}>
                            <NewsCard data={article} translations={translations} slug={NEWS_TYPES?.ARTICLE}/>
                        </div>
                    })
                }
            </div>
            <Pagination current={articlePage}
                        defaultPageSize={6}
                        onChange={onChangeArticlePaging}
                        itemRender={itemRender}
                        hideOnSinglePage={true}
                        className={`pagination-article`}
                        total={articlesData?.total_count || 0}/>
        </div>}
    </div>
}

export default Events
