import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";

export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                article: {
                    keyArgs: [],
                    merge (existing, incoming) {
                        return {
                            records: [...(existing?.records || []), ...(incoming?.records || [])]
                        }
                    },
                },
            },
        },
    },
});

const cms_edge = new HttpLink({
    // uri: 'http://173.212.233.220:7020/api/cms-edge/v1/graphql',
    uri: 'https://app.orangefit.am/api/cms-edge/v1/graphql',
})
const cms = new HttpLink({
    // uri: 'http://173.212.233.220:7010/api/cms/v1/graphql',
    uri: 'https://app.orangefit.am/api/cms/v1/graphql',
})

export const apolloClient = new ApolloClient({
    link: ApolloLink.split(
        operation => operation.getContext().clientName === 'cms',
        cms,
        cms_edge, //if above
    ),
    cache: cache,
});

