import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_CAFE = gql`
    query GetCafe{
        cafe{
            id
            title
            description
            menu_link
            image_url
            logo_file_url
            pdf_url
            updated_at
            images{
                id
                image_url
            }
        }
    }
`

export const useCafe = () => {
    const language = useReactiveVar(appLanguage)
    const { data, loading} = useQuery(GET_CAFE, {
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { data: data?.cafe, loading }
};
