import './mobileMenu.scss'
import { CloseMenuIcon, HeaderArrIcon} from "../../assets/images";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Dropdown, Menu, Space} from "antd";
import {useReactiveVar} from "@apollo/client";
import {appLanguage} from "../../graphql/reactive-variables";
import {apolloClient} from "../../graphql/client";
import {Languages} from "../../utils/constanats";
import {GetUrlWithLocal} from "../../utils/getUrlWithLocal";

function MobileMenu(props) {
    const {className, toggleMobileMenu,translations} = props
    const language = useReactiveVar(appLanguage)
    const location = useLocation()
    const navigate = useNavigate();

    function changeLanguage(lang) {
        if (language !== lang) {
            appLanguage(lang);
            localStorage.setItem('language', lang);
            apolloClient.resetStore();
            const pathname=location.pathname;
            const prevlang = pathname?.split('/').at(-1);
            if(prevlang === Languages.en || prevlang === Languages.hy){
                const pathnameWithoutLang= pathname?.slice(0, -prevlang.length)
                navigate(pathnameWithoutLang+lang, {replace: true})
            }
        }
    }


    const menuLanguage = (
        <Menu className={`languages-dropdown`}>
            {
                Object.values(Languages)?.map((code) => {
                    return language !== code &&
                        <Menu.Item key={code}
                                   onClick={() => changeLanguage(code)}
                                   className={'language-dropdown-item'}>
                            <div className="selected-language">
                                <span>{translations && translations[`header_languages_${code}`]}</span>
                            </div>
                        </Menu.Item>
                })
            }
        </Menu>
    );

    return <section className={`mobile-menu-wrapper ${className}`}>
        <div className={'right-menu'}>
            <div className={'close-menu'} onClick={toggleMobileMenu}>
                <CloseMenuIcon/>
            </div>

            <ul className="menu-block">
                <div className={'pages-link'}>
                    <NavLink to={GetUrlWithLocal('/fitness')} className={({isActive}) => (isActive ? "active" : 'none')} onClick={toggleMobileMenu}>
                        <li className="nav-item">{translations?.header_item_fitness}</li>
                    </NavLink>
                    <NavLink to={GetUrlWithLocal('/spa')} className={({isActive}) => (isActive ? "active" : 'none')} onClick={toggleMobileMenu}>
                        <li className="nav-item">{translations?.header_item_spa}</li>
                    </NavLink>
                    <NavLink to={GetUrlWithLocal('/cafe')} className={({isActive}) => (isActive ? "active" : 'none')} onClick={toggleMobileMenu}>
                        <li className="nav-item">{translations?.header_item_cafe}</li>
                    </NavLink>
                    <NavLink to={GetUrlWithLocal('/about')} className={({isActive}) => (isActive ? "active" : 'none')} onClick={toggleMobileMenu}>
                        <li className="nav-item">{translations?.header_item_about}</li>
                    </NavLink>
                    <NavLink to={GetUrlWithLocal('/news')} className={({isActive}) => (isActive ? "active" : 'none')} onClick={toggleMobileMenu}>
                        <li className="nav-item">{translations?.header_item_events}</li>
                    </NavLink>
                    <NavLink to={GetUrlWithLocal('/contact')} className={({isActive}) => (isActive ? "active" : 'none')} onClick={toggleMobileMenu}>
                        <li className="nav-item">{translations?.header_item_contact}</li>
                    </NavLink>
                </div>
                <div className={'language-block'}>
                    <Dropdown
                        trigger={['click']}
                        overlay={menuLanguage}
                        destroyPopupOnHide={false}
                        autoFocus={false}
                        autoAdjustOverflow={false}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <div className="nav-item">
                                    {Object.values(Languages).map(code => {
                                        if (code === language) {
                                            return translations && translations[`header_languages_${code}`]
                                        }
                                    })} <HeaderArrIcon/>
                                </div>
                            </Space>
                        </a>
                    </Dropdown>
                </div>
            </ul>
        </div>
    </section>
}

export default MobileMenu
