import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_FITNESS = gql`
    query GetFitness{
          fitness{
            id
            logo_url
            services{
                id
                title
                description
                image_url
            }
          }
    }
`

export const useFitness = () => {
    const language = useReactiveVar(appLanguage)
    const {data, loading} = useQuery(GET_FITNESS, {
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return {data: data?.fitness, loading}
};
