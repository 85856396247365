import './notFound.scss'
import {NotFoundIcon} from "../../assets/images";
import {useTranslations} from "../../graphql/query.hooks/translation.hook";


function NotFound(){
    const translations = useTranslations()

    return <div className={'notFound-wrapper'}>
        <NotFoundIcon/>
        <span>404</span>
        <span>{translations?.notFound_text}</span>
    </div>

}export default NotFound