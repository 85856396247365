import './classesModal.scss'
import { Modal } from "antd";
import StaffCard from "../../cards/staffCard/StaffCard";
import { useWorkoutOne } from "../../../../graphql/query.hooks/workoutOne.hook";
import {ModalCloseIcon} from "../../../../assets/images";

function ClassesModal (props) {
    const { closeModal, visible, classesModalData } = props
    const workoutOne = useWorkoutOne(classesModalData?.id)

    return <Modal
        visible={visible}
        wrapClassName={'classes-modal'}
        transitionName={""}
        closeIcon={<ModalCloseIcon/>}
        centered={true}
        maskClosable={true}
        onCancel={closeModal}>
        <div className={'classes-modal-wrapper'}>
            <div className={'classes-modal-info'}>
                <div className={'classes-modal-img'}>
                    <img src={workoutOne?.data?.image_url} alt=""/>
                </div>
                <div className={'classes-modal-title'}>
                    {workoutOne?.data?.title}
                </div>
                <div className={'classes-modal-description'}>
                    {workoutOne?.data?.description}
                </div>
            </div>
            {
                workoutOne?.data?.trainers?.length && <div className={'classes-modal-trainers'}>
                    <div className={'classes-modal-trainers-title'}>
                        staff
                    </div>
                    <div className={'classes-modal-trainers-wrapper'}>
                        {
                            workoutOne?.data?.trainers?.length && workoutOne?.data?.trainers.map(trainer => {
                                return <div className={'classes-modal-trainers-card'}>
                                    <StaffCard item={trainer}/>
                                </div>
                            })
                        }
                    </div>

                </div>
            }
        </div>
    </Modal>
}

export default ClassesModal
