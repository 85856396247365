import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_CONTACT = gql`
    query GetContact{
        contact{   
          id
          address
          phones
          working_times
          email
          url
          in_link
          fb_link
          tk_link
          ln_link
          yt_link
          tg_link
          privacy_policy
        }
    }
`

export const useContact = () => {
    const language = useReactiveVar(appLanguage)
    const { data, loading, fetchMore } = useQuery(GET_CONTACT, {
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { list: data?.contact, loading, fetchMore}
};
