import {BookingArrIcon, CheckboxIcon, MembershipCheckboxIcon} from "../../../../assets/images";
import './packageCard.scss'
import VisitModal from "../../modals/visitModal/VisitModal";
import {useState} from "react";


function PackageCard({data, translations}) {
    const [visitModalOpen, setVisitModalOpen] = useState(false)
    const [membershipId, setMembershipId] = useState('')

    function openVisitModal(id) {
        setVisitModalOpen(true)
        setMembershipId(id)
    }

    function closeVisitModal() {
        setVisitModalOpen(false)
        setMembershipId('')
    }

    return <div className={'items-wrapper'}>
        <div className={'name-block'}>
            {data?.name}
        </div>
        <div className={'items-header'}>
            <div className={'items-header-title'}>
                {data?.title}
            </div>
            <div className={'items-header-description'}>
                {data?.information}
            </div>
        </div>
        <div className={'items-bottom-block'}>
            <div className={'bottom-block-info-wrapper'}>
                <div className={'info-block'}>
                    {data?.description}
                </div>
                {/*<div className={'items-info-block'}>*/}
                {/*    {*/}
                {/*         data?.details.map((item, index) => {*/}
                {/*            console.log(item,'CheckboxIcon')*/}
                {/*            return <div className={'items-info'} key={index}>*/}
                {/*                <MembershipCheckboxIcon/> {item}*/}
                {/*            </div>*/}
                {/*        })*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
            <div className={'item-visit-block'}>
                {
                    data?.duration_options?.length ? <div className={'date-options-block'}>
                        {translations?.home_packages_duration_text}
                        <span>{data?.duration_options.join(", ")}</span>
                    </div> : ''
                }
                <button className={'item-visit-btn'} onClick={() => openVisitModal(data?.id)}>
                    {translations?.home_packages_btn_text} <BookingArrIcon/>
                </button>
            </div>
        </div>
        <VisitModal visible={visitModalOpen}
                    membershipId={membershipId}
                    closeVisitModal={closeVisitModal}/>
    </div>
}

export default PackageCard
