import './tickerBlock.scss'
import { useTranslations } from "../../../graphql/query.hooks/translation.hook";
import Marquee from "react-fast-marquee";

function TickerBlock () {
    const translations = useTranslations()

    return <div className={'ticker-wrapper'}>
        <Marquee speed={150}
                 gradient={false}>
            {translations?.homepage_ticker_block_text.split(',')?.length
                && translations?.homepage_ticker_block_text.split(',')?.map((item, index) => {
                    return <div className="ticker-item" key={item + index}>
                        <div>
                            {item}
                        </div>
                    </div>
                })}
        </Marquee>
    </div>

}

export default TickerBlock
