import './homepage.scss'
import MainBlock from "../../components/homepage/mainBlock/MainBlock";
import TickerBlock from "../../components/homepage/tickerBlock/TickerBlock";
import ServiceBlock from "../../components/homepage/serviceBlock/ServiceBlock";
import VideoBlock from "../../components/homepage/videoBlock/VideoBlock";
import EventsBlock from "../../components/homepage/eventsBlock/EventsBlock";
import AppBlock from "../../components/homepage/appBlock/AppBlock";
import MembershipBlock from "../../components/homepage/membershipBlock/MembershipBlock";
import FaqBlock from "../../components/homepage/faqBlock/FaqBlock";

//Hooks
import { useResource } from "../../graphql/query.hooks/resource.hook";
import { useTranslations } from "../../graphql/query.hooks/translation.hook";

function Homepage () {
    const resource = useResource()
    const translations = useTranslations()

    return <div className={'homepage-wrapper'}>
        <div className={'first-block'}>
            <MainBlock resource={resource}
                       translations={translations}/>
            <TickerBlock translations={translations}/>
        </div>
        <ServiceBlock translations={translations}/>
        <VideoBlock translations={translations}
                    resource={resource}/>
        <MembershipBlock translations={translations}/>
        <AppBlock />
        <EventsBlock translations={translations}/>
        <FaqBlock translations={translations}/>
    </div>
}

export default Homepage
