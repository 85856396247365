import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_ARTICLE_ONE = gql`
    query GetArticleOne($article_id_or_slug_eq:String!){
        article_one(where:{article_id_or_slug_eq:$article_id_or_slug_eq}) {
            id
            title
            description
            image_url
            cover_url
            created_at
            updated_at
            cover_url
            web_cover_url
            public_metric{
                id
                read_count
            }
        }
    }
`

export const useArticleOne = (articleId) => {
    const language = useReactiveVar(appLanguage)
    const { data, loading, fetchMore } = useQuery(GET_ARTICLE_ONE, {
        variables: { article_id_or_slug_eq: articleId },
        skip: !articleId,
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { data: data?.article_one, loading, fetchMore }
};
