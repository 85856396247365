import "./header.scss"

import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import { BurgerMenuIcon, CloseMenuIcon, HeaderArrIcon, Logo } from "../../assets/images";
import { appLanguage } from "../../graphql/reactive-variables";
import { Languages } from "../../utils/constanats";
import { useReactiveVar } from "@apollo/client";
import { apolloClient } from "../../graphql/client";
import {Dropdown, Menu, Space} from "antd";
import {GetUrlWithLocal, getUrlWithLocal} from "../../utils/getUrlWithLocal";

function Header (props) {
    const { mobileMenuOpen, toggleMobileMenu, translations } = props
    const language = useReactiveVar(appLanguage)
    const location = useLocation()
    const navigate = useNavigate();

    function changeLanguage (lang) {
        if (language !== lang) {
            appLanguage(lang);
            localStorage.setItem('language', lang);
            apolloClient.resetStore(); // refetch all queries.
            const pathname=location.pathname;
            const prevlang = pathname?.split('/').at(-1);
            if(prevlang === Languages.en || prevlang === Languages.hy){
                const pathnameWithoutLang= pathname?.slice(0, -prevlang.length)
                navigate(pathnameWithoutLang+lang, {replace: true})
            }
        }
    }


    const menuLanguage = (
        <Menu className={`languages-dropdown`}>
            {
                Object.values(Languages)?.map((code) => {
                    return language !== code &&
                        <Menu.Item key={code}
                                   onClick={() => changeLanguage(code)}
                                   className={'language-dropdown-item'}>
                            <div className="selected-language">
                                <span>{translations && translations[`header_languages_${code}`]}</span>
                            </div>
                        </Menu.Item>
                })
            }
        </Menu>
    );
    return <div className={'header-wrapper'}>
        <Link to={GetUrlWithLocal('/home')}>
            <div className={'orange-logo'}>
                <Logo title={''}/>
            </div>
        </Link>
        <ul className="nav-menu">
            <NavLink to={GetUrlWithLocal('/fitness')} className={({isActive}) => (isActive ? "active" : 'none')}>
                <li className="nav-item">{translations?.header_item_fitness}</li>
            </NavLink>
            <NavLink to={GetUrlWithLocal('/spa')} className={({isActive}) => (isActive ? "active" : 'none')}>
                <li className="nav-item">{translations?.header_item_spa}</li>
            </NavLink>
            <NavLink to={GetUrlWithLocal('/cafe')} className={({isActive}) => (isActive ? "active" : 'none')}>
                <li className="nav-item">{translations?.header_item_cafe}</li>
            </NavLink>
            <NavLink to={GetUrlWithLocal('/about')} className={({isActive}) => (isActive ? "active" : 'none')}>
                <li className="nav-item">{translations?.header_item_about}</li>
            </NavLink>
            <NavLink to={GetUrlWithLocal('/news')} className={({isActive}) => (isActive ? "active" : 'none')}>
                <li className="nav-item">{translations?.header_item_events}</li>
            </NavLink>
            <NavLink to={GetUrlWithLocal('/contact')} className={({isActive}) => (isActive ? "active" : 'none')}>
                <li className="nav-item">{translations?.header_item_contact}</li>
            </NavLink>
            <div className={'language-block'}>

                <Dropdown
                    trigger={['click']}
                    overlay={menuLanguage}
                    destroyPopupOnHide={false}
                    autoFocus={false}
                    autoAdjustOverflow={false}
                >
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <div className="selected-language">
                                {Object.values(Languages).map(code => {
                                    if (code === language) {
                                        return translations && translations[`header_languages_${code}`]
                                    }
                                })} <HeaderArrIcon/>
                            </div>
                        </Space>
                    </a>
                </Dropdown>
                          </div>

            <a className={`header_burger`}
               onClick={toggleMobileMenu}>
                <BurgerMenuIcon/>
            </a>
        </ul>
    </div>
}

export default Header
