import './serviceCard.scss'
import {Link} from "react-router-dom";
import {GetUrlWithLocal} from "../../../../utils/getUrlWithLocal";


function ServiceCard ({ data }) {
    return <Link to={GetUrlWithLocal(`/${data?.slug}`)} className={'service-item-wrapper'}>
        <div className={'service-item-img'}>
            <img src={data?.logo_url} alt=""/>
        </div>
        <span className={'service-gradient'}/>
        <div className={'service-item-title'}>
            {data?.title}
        </div>
        <div className={'service-item-texts'}>
            <div className={'service-hover-title'}>
                {data?.title}
            </div>
            <div className={'service-item-description'}>
                {data?.description}
            </div>
        </div>
    </Link>
}export default ServiceCard