import './serviceBlock.scss'
import { useFitness } from "../../../graphql/query.hooks/fitnes.hook";
import { useSpa } from "../../../graphql/query.hooks/spa.hook";
import { useCafe } from "../../../graphql/query.hooks/cafe.hook";
import { useTeam } from "../../../graphql/query.hooks/team.hook";
import ServiceCard from "../../uiElements/cards/serviceCard/ServiceCard";
import {usePool} from "../../../graphql/query.hooks/pool.hook";


function ServiceBlock (props) {
    const { translations } = props
    const spa = useSpa()
    const cafe = useCafe()
    const fitness = useFitness()
    const pool = usePool()

    const fitnessData = {
        logo_url: fitness?.data?.logo_url,
        slug: 'fitness',
        title: translations?.['home_service_fitness_title'] || 'fitness',
        description: translations?.['home_service_fitness_description'] || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.',
    }
    const teamData = {
        logo_url: pool?.data?.logo_file_url,
        slug:'pool',
        title: translations?.['home_service_team_title'] || 'team',
        description: translations?.['home_service_team_description'] || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.',
    }
    const cafeData = {
        logo_url: cafe?.data?.logo_file_url,
        slug:'cafe',
        title: translations?.['home_service_cafe_title'] || 'cafe',
        description: translations?.['home_service_cafe_description'] || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.',
    }
    const spaData = {
        logo_url: spa?.data?.logo_url,
        slug:'spa',
        title: translations?.['home_service_spa_title'] || 'spa',
        description: translations?.['home_service_spa_description'] || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.',
    }

    return <div className={'service-wrapper'}>
        <div className={'service-header-block'}>
            <div className={'service-block-title'}>
                {translations?.homepage_services_title}
            </div>
            <div className={'service-block-description'}>
                {translations?.homepage_services_description}
            </div>
        </div>
        <div className={'services-block'}>
            <ServiceCard data={fitnessData}/>
            <ServiceCard data={teamData}/>
            <ServiceCard data={cafeData}/>
            <ServiceCard data={spaData}/>
        </div>
    </div>

}export default ServiceBlock

